import React from 'react';
import { projects } from '../../data/projects';

// Components
import ProjectLanding from '../ProjectLanding';
import Footer from '../Footer';
import Text from '../Text';
import Goals from '../Goals';
import Navbar from '../Navbar';

// Images
import typography from '../../assets/images/cotalker/CotalkerDS_Typography.png';
import colors from '../../assets/images/cotalker/CotalkerDS_Colors.png';
import buttons from '../../assets/images/cotalker/CotalkerDS_Buttons.png';
import checkboxradios from '../../assets/images/cotalker/CotalkerDS_CheckboxesRadios.png';
import tablecomps from '../../assets/images/cotalker/CotalkerDS_Table.png';
import organisms from '../../assets/images/cotalker/CotalkerDS_Organisms.png';
import templates from '../../assets/images/cotalker/CotalkerDS_Templates.png';
import page from '../../assets/images/cotalker/CotalkerDS_Page.png';

const CotalkerDS = () => {
	const { name, tags, context, process, learnings } = projects[5];
	const { year, client, team, role } = projects[5].details;
	const { main_goal, specific_goals } = projects[5].goals;
	return (
		<div>
			<Navbar />

			<div className="container">
				<div className="content">
					<ProjectLanding
						title={name}
						tags={tags.map((tag, index) => (
							<span key={index} className="tag is-rounded">
								{tag}
							</span>
						))}
						year={year}
						client={client}
						team={team}
						role={role}
					/>
					<hr />

					<div className="columns is-centered">
						<div className="column is-four-fifths">
							{/* Contexto */}
							<section className="section">
								<Text subtitle={'Contexto'} textarr={context} />
							</section>

							{/* Objetivos */}
							<section className="section">
								<Goals maingoal={main_goal} goals={specific_goals} />
							</section>

							{/* Proceso */}
							<section className="section">
								<h2 className="subtitle">Proceso</h2>
								<p>{process[0]}</p>
								<p>{process[1]}</p>
								<div className="columns">
									<div className="column">
										<figure className="image">
											<img
												src={typography}
												alt="Tipografía base usada para la plataforma Cotalker."
											></img>
										</figure>
									</div>
									<div className="column">
										<figure className="image">
											<img
												src={colors}
												alt="Colores base usados para la plataforma Cotalker."
											></img>
										</figure>
									</div>
								</div>
								<div className="columns">
									<div className="column">
										<figure className="image">
											<img
												src={buttons}
												alt="Variedad de botones en distintos estilos."
											></img>
										</figure>
									</div>
									<div className="column">
										<figure className="image">
											<img
												src={checkboxradios}
												alt="Componentes de formularios, checkboxes y radio buttons."
											></img>
										</figure>
										<figure className="image">
											<img
												src={tablecomps}
												alt="Componentes de tabla, como celdas, encabezados y footer."
											></img>
										</figure>
									</div>
								</div>
								<p>{process[2]}</p>
								<figure className="image">
									<img
										src={organisms}
										alt="Organismos que se componen de distintos componentes más pequeños."
									></img>
								</figure>
								<figure className="image">
									<img
										src={templates}
										alt="Templates que combinan distintos componentes más pequeños."
									></img>
								</figure>
							</section>

							{/* Aprendizajes */}
							<section className="section">
								<Text subtitle={'Aprendizajes'} textarr={learnings} />
								<figure className="image">
									<img
										src={page}
										alt="Página que combina todos los componentes anteriormente creados."
									></img>
								</figure>
							</section>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default CotalkerDS;
