import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Aplicaton from './pages/Aplicaton';
import Cuidemonos from './pages/Cuidemonos';
import DecideChile from './pages/DecideChile';
import FactoringSecurity from './pages/FactoringSecurity';
import PcFactory from './pages/PcFactory';
import CotalkerDS from './pages/CotalkerDS';
import Dataviz from './pages/Dataviz';
import About from './pages/About';

const App = () => {
	return (
		<div>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="aplicaton" element={<Aplicaton />} />
				<Route path="cuidemonos" element={<Cuidemonos />} />
				<Route path="decidechile" element={<DecideChile />} />
				<Route path="factoringsecurity" element={<FactoringSecurity />} />
				<Route path="pcfactory" element={<PcFactory />} />
				<Route path="cotalkerds" element={<CotalkerDS />} />
				<Route path="dataviz" element={<Dataviz />} />
				<Route path="about" element={<About />} />
			</Routes>
		</div>
	);
};

export default App;
