import React from 'react';
import { projects } from '../../data/projects';

// Components
import Footer from '../Footer';
import Text from '../Text';
import Navbar from '../Navbar';

// Images
import afiche_taller from '../../assets/images/dataviz/Afiche_Dataviz.png';
import dashboard_inteligencia from '../../assets/images/dataviz/dashboard.png';
import decidechile1 from '../../assets/images/dataviz/DecideChile_Constitucionales.png';
import constitucionales_dashboard from '../../assets/images/dataviz/Distrito-Scrollable.png';

const Dataviz = () => {
	const {
		name,
		tags,
		taller_mercado,
		inteligencia_electrica,
		decidechile,
		constitucionales
	} = projects[6];

	return (
		<div>
			<Navbar />
			<div className="container">
				<div className="content">
					<div className="columns is-centered">
						<div className="column is-four-fifths">
							<div className="level">
								<div className="level-left">
									<h1 id="project-title" className="title is-1">
										{name}
									</h1>
								</div>
								<div className="level-right">
									<span className="tags">
										{tags.map((tag, index) => (
											<span key={index} className="tag is-rounded">
												{tag}
											</span>
										))}
									</span>
								</div>
							</div>
							<hr />

							{/* Taller Mercado II */}
							<section className="section">
								<Text
									subtitle={taller_mercado.subtitle}
									textarr={taller_mercado.content}
								/>

								<figure className="image">
									<img
										src={afiche_taller}
										alt="Afiche hecho en Processing que muestra datos de Los Simpsons."
									></img>
								</figure>
							</section>

							{/* Inteligencia Electrica */}
							<section className="section">
								<Text
									subtitle={inteligencia_electrica.subtitle}
									textarr={inteligencia_electrica.content}
								/>
								<figure className="image">
									<img
										src={dashboard_inteligencia}
										alt="Dashboard con información sobre cliente de suministro eléctrico."
									></img>
								</figure>
							</section>

							{/* DecideChile */}
							<section className="section">
								<Text
									subtitle={decidechile.subtitle}
									textarr={decidechile.content}
								/>

								<figure className="image">
									<img
										src={decidechile1}
										alt="Diferentes vistas de visualizaciones de datos del Plebiscito Nacional 2020."
									></img>
								</figure>
							</section>

							{/* Constituyente */}
							<section className="section">
								<Text
									subtitle={constitucionales.subtitle}
									textarr={constitucionales.content}
								/>
								<figure className="image">
									<img
										src={constitucionales_dashboard}
										alt="Dashboard con información sobre cliente de suministro eléctrico."
									></img>
								</figure>
							</section>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default Dataviz;
