import React from 'react';
import Intro from '../Intro';
import Navbar from '../Navbar';
import Projects from '../pages/Projects';
import Footer from '../Footer';

const App = () => {
	return (
		<main>
			<Navbar />
			<Intro />
			<Projects />
			<Footer />
		</main>
	);
};

export default App;
