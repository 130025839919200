export const previews = [
	{
		url: 'cotalkerds',
		name: 'Cotalker DS',
		preview_img: 'assets/images/cotalkerds-preview.png',
		preview_img_alt: 'Image of the Design System for Cotalker app.',
		short_description:
			'Diseño y construcción del sistema de diseño de Cotalker.'
	},
	{
		url: 'pcfactory',
		name: 'PC Factory',
		preview_img: 'assets/images/pcfactory-preview.png',
		preview_img_alt: 'Image of the PC Factory project preview',
		short_description:
			'Nuevo diseño UI para tienda chilena online de productos electrónicos y tecnología.'
	},
	{
		url: 'aplicaton',
		name: 'Aplicatón',
		preview_img: 'assets/images/aplicaton-preview.png',
		preview_img_alt: 'Image of the Aplicatón project preview',
		short_description:
			'Aplicación móvil para la campaña 2020 de Teletón con el objetivo de instar a las personas a participar activamente de eventos y mantenerse al tanto de noticias.'
	},
	{
		url: 'cuidemonos',
		name: 'Cuidémonos',
		preview_img: 'assets/images/cuidemonos-preview.png',
		preview_img_alt: 'Image of the Cuidémonos project preview',
		short_description:
			'Aplicación web y móvil de trazabilidad y monitoreo de casos Covid-19 para organizaciones y empresas.'
	},
	{
		url: 'decidechile',
		name: 'DecideChile 2020',
		preview_img: 'assets/images/decidechile-preview.png',
		preview_img_alt: 'Image of DecideChile project preview',
		short_description:
			'Proyecto de visualización de datos electorales del Plebiscito Nacional 2020.'
	},
	{
		url: 'factoringsecurity',
		name: 'Factoring Security',
		preview_img: 'assets/images/factoring-preview.png',
		preview_img_alt: 'Image of the Factoring Security project preview',
		short_description:
			'Diseño UI para proceso 100% online de factoring. Formato desktop y mobile.'
	},
	{
		url: 'dataviz',
		name: 'Dataviz',
		preview_img: 'assets/images/datavis-preview.png',
		preview_img_alt: 'Image of a Data Visualization project preview',
		short_description:
			'Proyectos variados de visualización de datos. Mayoritariamente propuestas que no se desarrollaron, pero que fue valioso diseñar.'
	}
];
