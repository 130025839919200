import React from 'react';
import { Link } from 'react-router-dom';
import monogram_light from '../assets/images/Navbar_Monogram_Light.svg';
import monogram_dark from '../assets/images/Navbar_Monogram_Dark.svg';

const Navbar = () => {
	const [isActive, setisActive] = React.useState(false);
	return (
		<nav className="navbar" role="navigation" aria-label="main navigation">
			<div className="navbar-brand">
				<Link to="..">
					<picture>
						<source
							srcSet={monogram_dark}
							media="(prefers-color-scheme: dark)"
						/>
						<img
							className="navbar-item"
							src={monogram_light}
							width="100"
							alt="Constanza Morales monogram"
						/>
					</picture>
				</Link>

				<a
					onClick={(event) => {
						event.preventDefault();
						setisActive(!isActive);
					}}
					role="button"
					className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
					aria-label="menu"
					aria-expanded="false"
					data-target="navMenu"
					href=" "
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>
			</div>

			<div
				id="navMenu"
				className={`navbar-menu ${isActive ? 'is-active' : ''}`}
			>
				<div className="navbar-end">
					<div className="navbar-item">
						<Link to="../about">
							<p className="navbar-item">Sobre mí</p>
						</Link>
					</div>
					<div className="navbar-item">
						<div className="buttons">
							<Link
								to="https://drive.google.com/file/d/1Vpg3Bry5bjyc-zaLbf_P0_NgYlmT5ha5/view?usp=sharing"
								target="_blank"
							>
								<p className="button is-primary">
									<strong>Ver CV</strong>
								</p>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
