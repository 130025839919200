import React from 'react';

// Components
import Navbar from '../Navbar';
import Footer from '../Footer';

// Images
import photo from '../../assets/images/avatarvector.png';

const About = () => {
	return (
		<div>
			<Navbar />
			<section className="section">
				<div className="columns is-centered">
					<div className="column is-two-fifths">
						<figure className="image">
							<img
								className="is-rounded"
								src={photo}
								alt="Dashboard con información sobre cliente de suministro eléctrico."
							></img>
						</figure>
					</div>

					<div className="column">
						<h1 className="title">Sobre mí</h1>

						<div className="block">
							¡Hola! Soy Constanza, aunque prefiero que me digan Coni. Soy una
							diseñadora chilena con 6 años de experiencia en productos
							digitales.
						</div>
						<div className="block">
							Cursé mi educación superior en la Pontifica Universidad Católica
							donde estudié Diseño. Durante mis últimos años allí descubrí el
							diseño de productos digitales y la experiencia de usuario, y desde
							entonces me dedico a crear soluciones centradas en las personas.
						</div>
						<div className="block">
							Durante mi carrera he colaborado en proyectos del área de la
							energía eléctrica, retail, banca, visualización de datos y
							automatización de procesos, participando en soluciones a medida y
							productos con un foco en SaaS.
						</div>
						<div className="block">
							Cuando no estoy trabajando, me puedes encontrar aprendiendo sobre
							nuevos temas, leyendo algún libro o visitando algún lugar que
							ofrezca buena comida.
						</div>
						<div className="block">
							Si mi perfil te pareció interesante, puedes contactarme a través
							de mi correo{' '}
							<a href="mailto:constanzabmorales@gmail.com">
								constanzabmorales@gmail.com
							</a>{' '}
							o enviándome un mensaje en{' '}
							<a href="https://www.linkedin.com/in/cbmorales/">LinkedIn.</a>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</div>
	);
};

export default About;
