import React from 'react';
import { previews } from '../../data/previews.js';
import { Link } from 'react-router-dom';

const Projects = () => {
	return (
		<section id="projects" className="section projects">
			<div className="grid is-col-min-12">
				{previews.map((preview, index) => (
					<div key={index} className="cell projects-cards">
						<Link to={preview.url} key={index}>
							<div className="card">
								<div className="card-image">
									<figure className="image is-4by3">
										<img
											src={preview.preview_img}
											alt={preview.preview_img_alt}
										/>
									</figure>
								</div>
								<div className="card-content">
									<div className="content">
										<p className="title is-6">{preview.name}</p>
									</div>
								</div>
							</div>
						</Link>
					</div>
				))}
			</div>
		</section>
	);
};

export default Projects;
