import React from 'react';
const Footer = () => {
	return (
		<footer className="footer">
			<div className="content has-text-centered">
				<p>Coded with &hearts; by Coni &copy; {new Date().getFullYear()}</p>
				<a
					className="linkedin"
					href="https://www.linkedin.com/in/cbmorales/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<span className="icon">
						<i className="fab fa-linkedin-in"></i>
					</span>
				</a>
				<a
					className="github"
					href="https://github.com/constanzamorales"
					target="_blank"
					rel="noopener noreferrer"
				>
					<span className="icon">
						<i className="fab fa-github"></i>
					</span>
				</a>

				<a className="email" href="mailto:constanzabmorales@gmail.com">
					<span className="icon">
						<i className="fas fa-envelope"></i>
					</span>
				</a>
			</div>
		</footer>
	);
};

export default Footer;
