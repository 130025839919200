import React from 'react';
import { projects } from '../../data/projects';

// Components
import ProjectLanding from '../ProjectLanding';
import Footer from '../Footer';
import Text from '../Text';
import Goals from '../Goals';
import Navbar from '../Navbar';

// Images
import gruposecurity from '../../assets/images/factoringsecurity/grupo_security.png';
import userflow from '../../assets/images/factoringsecurity/userflow_factoring.png';
import wireframe_flow from '../../assets/images/factoringsecurity/flujos-factoring.png';
import landingcomparacion from '../../assets/images/factoringsecurity/Factoring_LandingComparacion.png';
import resumencomparacion from '../../assets/images/factoringsecurity/Factoring_ResumenComparacion.png';
import mobile from '../../assets/images/factoringsecurity/Factoring_Mobile.png';
import mockups from '../../assets/images/factoringsecurity/mockups.png';

const FactoringSecurity = () => {
	const { name, tags, context, users, process, learnings } = projects[3];
	const { year, client, team, role } = projects[3].details;
	const { main_goal, specific_goals } = projects[3].goals;

	return (
		<div>
			<Navbar />

			<div className="project">
				<div className="container">
					<div className="content">
						<ProjectLanding
							title={name}
							tags={tags.map((tag, index) => (
								<span key={index} className="tag is-rounded">
									{tag}
								</span>
							))}
							year={year}
							client={client}
							team={team}
							role={role}
						/>
						<hr />
						<div className="columns is-centered">
							<div className="column is-four-fifths">
								{/* Contexto */}
								<section className="section">
									<Text subtitle={'Contexto'} textarr={context} />
									<figure className="image">
										<img
											src={gruposecurity}
											alt="Screenshot de la página de inicio de Grupo Security"
										></img>
										<figcaption>
											Screenshot de la página de inicio de Grupo Security
										</figcaption>
									</figure>
								</section>

								{/* Objetivos */}
								<section className="section">
									<Goals maingoal={main_goal} goals={specific_goals} />
								</section>

								{/* Usuarios */}
								<section className="section">
									<Text subtitle={'Usuarios'} textarr={users} />
								</section>

								{/* Proceso */}
								<section className="section">
									<Text subtitle={'Proceso'} textarr={process} />

									<div className="block">
										<figure className="image">
											<img
												src={userflow}
												alt="Diagrama con flujos de usuarios."
											></img>
											<figcaption>Diagrama con flujos de usuarios.</figcaption>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img
												src={wireframe_flow}
												alt="Wireflow (wireframe userflow)."
											></img>
											<figcaption>Wireflow (wireframe userflow).</figcaption>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img
												src={landingcomparacion}
												alt="Wireframe vs alta fidelidad del landing."
											></img>
											<figcaption>
												Wireframe vs alta fidelidad del landing.
											</figcaption>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img
												src={resumencomparacion}
												alt="Wireframe vs alta fidelidad del resumen para el usuario."
											></img>
											<figcaption>
												Wireframe vs alta fidelidad del resumen para el usuario.
											</figcaption>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img src={mobile} alt="Diseño móvil."></img>
											<figcaption>Diseño móvil.</figcaption>
										</figure>
									</div>
								</section>

								{/* Aprendizajes */}
								<section className="section">
									<Text subtitle={'Aprendizajes'} textarr={learnings} />

									<div className="block">
										<figure className="image">
											<img
												src={mockups}
												alt="Mockups con el diseño final."
											></img>
										</figure>
									</div>
								</section>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default FactoringSecurity;
