import React from 'react';
import { projects } from '../../data/projects';

// Components
import ProjectLanding from '../ProjectLanding';
import Footer from '../Footer';
import Text from '../Text';
import Goals from '../Goals';
import Navbar from '../Navbar';

// Images
import referentes from '../../assets/images/cuidemonos/Cuidemonos_Contexto.png';
import flujocheckin from '../../assets/images/cuidemonos/flujo_checkin.png';
import flujo3 from '../../assets/images/cuidemonos/flujo3.png';
import earlyui from '../../assets/images/cuidemonos/early-ui.png';
import correcciones from '../../assets/images/cuidemonos/correcciones.png';
import hotjar1 from '../../assets/images/cuidemonos/Cuidemonos_Hotjar1.png';
import hotjar2 from '../../assets/images/cuidemonos/Cuidemonos_Hotjar2.png';
import finalscreens from '../../assets/images/cuidemonos/final-screens.png';
import mockup from '../../assets/images/cuidemonos/floating-phones-mockup.png';

const Cuidemonos = () => {
	const { name, tags, context, users, process, analysis, learnings } =
		projects[1];
	const { year, client, team, role } = projects[1].details;
	const { main_goal, specific_goals } = projects[1].goals;

	return (
		<div>
			<Navbar />
			<div className="project">
				<div className="container">
					<div className="content">
						<ProjectLanding
							title={name}
							tags={tags.map((tag, index) => (
								<span key={index} className="tag is-rounded">
									{tag}
								</span>
							))}
							year={year}
							client={client}
							team={team}
							role={role}
						/>
						<hr />

						<div className="columns is-centered">
							<div className="column is-four-fifths">
								{/* Contexto */}
								<section className="section">
									<Text subtitle={'Contexto'} textarr={context} />
									<figure className="image">
										<img
											src={referentes}
											alt="Arriba: Ada Health app. Abajo: NHS Covid-19 app."
										></img>
										<figcaption>
											Arriba: Ada Health app. Abajo: NHS Covid-19 app.
										</figcaption>
									</figure>
								</section>

								{/* Objetivos */}
								<section className="section">
									<Goals maingoal={main_goal} goals={specific_goals} />
								</section>

								{/* Users */}
								<section className="section">
									<Text subtitle={'Users'} textarr={users} />
								</section>

								{/* Process */}
								<section className="section">
									<Text subtitle={'Proceso'} textarr={process} />
									<div className="block">
										<figure className="image">
											<img
												src={flujocheckin}
												alt="Flujo inicial del check-in para Cuidémonos."
											></img>
											<figcaption>
												Flujo inicial del check-in para Cuidémonos.
											</figcaption>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img
												src={flujo3}
												alt="Diagrama que muestra lo más cercano al flujo actual de la app."
											></img>
											<figcaption>
												Diagrama que muestra lo más cercano al flujo actual de
												la app.
											</figcaption>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img
												src={earlyui}
												alt="Pantallas a color de la UI inicial."
											></img>
											<figcaption>
												Pantallas a color de la UI inicial.
											</figcaption>
										</figure>
									</div>
								</section>

								{/* Analisis y correcciones */}
								<section className="section">
									<Text
										subtitle={'Análisis de experiencia y correcciones'}
										textarr={analysis}
									/>
									<div className="block">
										<figure className="image">
											<img
												src={correcciones}
												alt="Pantalla con flechas que apuntan a secciones de la UI para explicarlas."
											></img>
										</figure>
									</div>
									<div className="block">
										<figure className="image">
											<img
												src={hotjar1}
												alt="Pantallazos de la app en desktop con el trayecto del cursor de los usuarios trackeados con Hotjar."
											></img>
										</figure>
									</div>
									<div className="block">
										<figure className="image">
											<img
												src={hotjar2}
												alt="Pantallazos de la app en desktop con el trayecto del cursor de los usuarios trackeados con Hotjar."
											></img>
										</figure>
										<figcaption>
											Pantallazos de la app en desktop con el trayecto del
											cursor de los usuarios trackeados con Hotjar.
										</figcaption>
									</div>
								</section>

								{/* Aprendizajes */}
								<section className="section">
									<Text subtitle={'Aprendizajes'} textarr={learnings} />
									<figure className="image">
										<img
											src={finalscreens}
											alt="Sucesión de pantallas con el diseño definitivo de la app."
										></img>
										<figcaption>
											Sucesión de pantallas con el diseño definitivo de la app.
										</figcaption>
									</figure>
								</section>

								{/* Final image */}
								<section className="section">
									<figure className="image">
										<img
											src={mockup}
											alt="Dos teléfonos flotantes que muestran la aplicación."
										></img>
									</figure>
								</section>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Cuidemonos;
