import React from 'react';
import { projects } from '../../data/projects';

// Components
import ProjectLanding from '../ProjectLanding';
import Footer from '../Footer';
import Text from '../Text';
import Goals from '../Goals';
import Navbar from '../Navbar';

// Images
import contexto1 from '../../assets/images/decidechile/Lunes-21-Migrarphoto01-1-e1597627734377.jpg';
import contexto2 from '../../assets/images/decidechile/pantallas_1024x768-01-e1577722740408.png';
import protopersonas from '../../assets/images/decidechile/DecideChile_Protopersonas.png';
import lofi1 from '../../assets/images/decidechile/DecideChile_Iteraciones1.png';
import lofi2 from '../../assets/images/decidechile/DecideChile_Iteraciones2.png';
import lofi3 from '../../assets/images/decidechile/DecideChile_Iteraciones3.png';
import mobile1 from '../../assets/images/decidechile/decidechile-prop-mobile-1.png';
import mobile2 from '../../assets/images/decidechile/decidechile-prop-mobile-2.png';
import mobile3 from '../../assets/images/decidechile/decidechile-prop-mobile-3.png';
import gif1 from '../../assets/images/decidechile/DecideChile_09032020.gif';
import gif2 from '../../assets/images/decidechile/DecideChile-2.gif';
import mobile4 from '../../assets/images/decidechile/decidechile-prop-mobile-4.png';
import finalmockup from '../../assets/images/decidechile/decidechile-mockup.png';

const DecideChile = () => {
	const { name, tags, context, users, process, challenges, learnings } =
		projects[2];
	const { year, client, team, role } = projects[2].details;
	const { main_goal, specific_goals } = projects[2].goals;

	return (
		<div>
			<Navbar />

			<div className="project">
				<div className="container">
					<div className="content">
						<ProjectLanding
							title={name}
							tags={tags.map((tag, index) => (
								<span key={index} className="tag is-rounded">
									{tag}
								</span>
							))}
							year={year}
							client={client}
							team={team}
							role={role}
						/>
						<hr />
						<div className="columns is-centered">
							<div className="column is-four-fifths">
								{/* Contexto */}
								<section className="section">
									<Text subtitle={'Contexto'} textarr={context} />
									<figure className="image">
										<img
											src={contexto1}
											alt="Marcha masiva en Chile, 2019."
										></img>
										<figcaption>Marcha masiva en Chile, 2019.</figcaption>
									</figure>
									<figure className="image">
										<img
											src={contexto2}
											alt="Gráfica para el Plebiscito 2020."
										></img>
										<figcaption>Gráfica para el Plebiscito 2020.</figcaption>
									</figure>
								</section>

								{/* Objetivos */}
								<section className="section">
									<Goals maingoal={main_goal} goals={specific_goals} />
								</section>

								<section className="section">
									<Text subtitle={'Usuarios'} textarr={users} />
									<figure className="image">
										<img src={protopersonas} alt="Proto-personas."></img>
										<figcaption>Proto-personas.</figcaption>
									</figure>
								</section>

								{/* Proceso */}
								<section className="section">
									<Text subtitle={'Proceso'} textarr={process} />
									<div className="block">
										<figure className="image">
											<img src={lofi1} alt="Prototipos en baja fidelidad"></img>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img src={lofi2} alt="Prototipos en baja fidelidad"></img>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img src={lofi3} alt="Prototipos en baja fidelidad"></img>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img
												src={mobile1}
												alt="Prototipos en formato móvil"
											></img>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img
												src={mobile2}
												alt="Prototipos en formato móvil"
											></img>
										</figure>
									</div>

									<div className="block">
										<figure>
											<img
												src={mobile3}
												alt="Prototipos en formato móvil"
											></img>
										</figure>
									</div>
								</section>

								{/* Desafios */}
								<section className="section">
									<Text subtitle={'Desafíos'} textarr={challenges} />
									<div className="level">
										<div className="level-item">
											<figure className="image">
												<img
													className="has-ratio"
													width="100"
													src={gif1}
													alt="Prototipos interactivos hechos con Principle donde se muestran los flujos de navegación de resultados a nivel nacional y regional."
												></img>
											</figure>
										</div>

										<div className="level-item">
											<figure className="image">
												<img
													className="has-ratio"
													width="100"
													src={gif2}
													alt="Prototipos interactivos hechos con Principle donde se muestran los flujos de navegación de resultados a nivel nacional y regional."
												></img>
											</figure>
										</div>
									</div>

									<div className="block">
										<figure className="image">
											<img
												src={mobile4}
												alt="Últimas propuestas de diseño móvil."
											></img>
											<figcaption>
												Últimas propuestas de diseño móvil.
											</figcaption>
										</figure>
									</div>
								</section>

								{/* Aprendizajes */}
								<section className="section">
									<Text subtitle={'Aprendizajes'} textarr={learnings} />
									<figure className="image">
										<img src={finalmockup} alt="Mockup final."></img>
									</figure>
								</section>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default DecideChile;
