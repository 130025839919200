import React from 'react';
const Text = ({ subtitle, textarr }) => {
	return (
		<div className="block">
			<div className="content">
				<h2 className="subtitle">{subtitle}</h2>
				{textarr.map((element, index) => (
					<p key={index} className="block">
						{element}
					</p>
				))}
			</div>
		</div>
	);
};

export default Text;
