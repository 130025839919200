export const projects = [
	{
		name: 'Aplicatón',
		tags: ['mobile', 'sketching', 'wireframes', 'ideation'],
		details: {
			year: '2019-2020',
			client: 'Teletón',
			team: ['Project Manager', 'Tres diseñadoras', 'Desarrolladores'],
			role: [
				'Resumir features y secciones al inicio del proyecto.',
				'Dibujar bocetos y wireframes de las primeras ideas.',
				'Proponer los primeros flujos de usuario.',
				'Construir las primeras ideas de la interfaz en Sketch.',
				'Guiar al equipo de diseño UI/UX durante todo el proceso.'
			]
		},
		context: [
			'A mediados del 2019, el cliente buscaba actualizar la aplicación móvil para la próxima campaña de la Teletón, agendada para el 29 y 30 de noviembre del mismo año.'
		],
		goals: {
			main_goal:
				'Instar a a las personas a participar en la Teletón desde sus celulares. Esta necesidad surgió después de que Teletón se dio cuenta que el público ya no estaba tan conectado a el contenido televisivo que transmitían durante la campaña.',
			specific_goals: [
				'Mostrar eventos relacionados a la Teletón durante la campaña, ya sean actividades en colegios, vecindarios, fundaciones, etc.',
				'Informar sobre noticias y eventos de la Teletón.',
				'Visualizar los últimos tweets de la cuenta oficial de Teletón.',
				'Permitir que las personas puedan inscribirse y participar en los eventos que mostrará la app.'
			]
		},
		users: [
			'El arquetipo de usuario de la aplicación se definió con el equipo de diseñadoras a través de la construcción de user personas, basándonos en todo lo que sabíamos de la Teletón. En la construcción de arquetipos monitoreé el trabajo de la diseñadora a cargo de esta tarea, para asegurar que estábamos yendo por el camino correcto.'
		],
		process: [
			'Haciendo las user personas se tenía una idea básica de lo que se necesitaba hacer. En esta etapa del proceso de diseño, siento que —personalmente— las ideas fluyen mejor cuando se recurre a bocetos rápidos. Inicialmente tenía una idea vaga de cómo sería la interfaz de la app, y poner eso en un boceto me ayudó a aterrizar esa idea. Estas ideas se discutieron con el Project Manager a cargo, quien nos ayudó a definir la funcionalidades de la aplicación y a hacer sentido a lo que se estaba proponiendo desde el lado del diseño, lo que permitió que se pudiese acotar ideas a un entregable que se pudiese desarrollar dentro del plazo establecido',
			'A esto le siguió la construcción de prototipos de baja fidelidad en Sketch para formalizar lo que se había conversado mientras se trabajó en la pizarra, y ver cómo todo se distribuía en las dimensiones de un teléfono móvil. En esta etapa se experimentó con un prototipo en escala de grises animado, para asegurar que el flujo de navegación fuese comprensible.'
		],
		user_testing: [
			'Después de algunas iteraciones con el equipo para definir estilo gráfico, teníamos un prototipo de alta fidelidad que usamos para realizar algunas pruebas con potenciales usuarios. Para esto se usó primero Maze, que permitió evaluar el comportamiento de los usuarios a través de una prueba remota no guiada, y a cambio obtuvimos un reporte de usabilidad. Este ejercicio se realizó dos veces.'
		],
		findings: [
			'Con ambos reportes de usabilidad de Maze, además de comentarios recibidos por compañeros de trabajo con los que se probó la aplicación en persona, salieron a la luz un montón de falencias en términos de jerarquización, uso correcto de textos y problemas de navegación. Uno de los principales cambios fue mover el menú abajo, para permitir un acceso más cómodo, al estar más cerca del alcance de los pulgares. Se añadió un botón flotante para aportar monetariamente, ya que es de las acciones en las que más pensaban los usuarios al pensar en la Teletón. Además, se unificó la sección de calendario y mapa, ya que ambos ofrecían una visión de los eventos relacionados con la Teletón.'
		],
		learnings: [
			'Formar parte del proceso de diseño de un aplicación móvil fue realmente enriquecedor, en el sentido de que fue una de mis primeras experiencias diseñando en este formato. El hecho de que el cliente haya sido una de las instituciones más conocidas en Chile le agregó su cuota de estrés al proyecto. Una de las instancias que más disfruté fue definir las características que tendría la aplicación junto al Project Manager. Aprecio esos momentos de colaboración con profesionales de otras áreas.',
			'Si bien logramos el propósito, haría ciertas cosas de manera distinta, siento que siempre es bueno hacer una autocrítica para seguir mejorando. Empezando por estudiar más a los usuarios finales, y testear más la aplicación, sobre todo en etapas más tempranas.Creo que una gran deuda fue no poner atención a la accesibilidad de la aplicación, considerando que Teletón trabaja con personas con discapacidades.',
			'También me gustaría haber pasado más tiempo en la etapa de ideación, e implementar alguna técnica de diseño como brainstorming, card sorting o empathy maps para lograr un producto más completo, comprensible y coherente. Sin embargo, mucho depende del contexto, los plazos y el presupuesto para el proyecto. Aun así, lo recuerdo como una experiencia muy valiosa y me encantaría tomar un desafío similar.'
		]
	},
	{
		name: 'Cuidémonos',
		tags: ['mobile', 'wireframes', 'user flows', 'usability'],
		details: {
			year: '2020-2021',
			client: 'Varios',
			team: ['Project Manager', 'Tres diseñadoras', 'Desarrolladores'],
			role: [
				'Proponer flujos de usuario en etapas tempranas del proyecto.',
				'Revisar interacciones de los usuarios con Hotjar.',
				'Proponer mejoras de usabilidad.',
				'Guiar al equipo de diseño UI/UX durante todo el proceso.'
			]
		},
		context: [
			'A inicios del año 2020, el virus SARS-CoV-2, causante del coronavirus (COVID-19) se expandió alrededor del mundo, causando una pandemia que obligó a las personas a adaptarse a una vida donde el hogar es el principal lugar de trabajo, educación y esparcimiento; y a empresas, organizaciones e instituciones a optar por un modo de trabajo remoto. El reporte y la trazabilidad de salud de cada empleado se volvió fundamental, lo que derivó en que Unholster ideara y desarrollara una aplicación móvil para estos propósitos.'
		],
		goals: {
			main_goal:
				'Trazar, monitorear y gestionar los contagios dentro de las organizaciones.',
			specific_goals: [
				'Testear periódicamente a los colaboradores.',
				'Monitorear contagios, cuarentenas y personas expuestas.',
				'Comunicar medidas y protocolos a nivel de organización.'
			]
		},
		users: [
			'Colaborador/a: Persona en edad laboral (18 a 65 años apróx.), empleada.',
			'Administrador/a: Persona en edad laboral, empleada, con un cargo o posición relacionado a gestión o liderazgo.'
		],
		process: [
			'Para empezar, el equipo se enfocó en dos funciones: monitorear temperatura y saber si la persona salió de casa.La app sería en sus inicios un cuestionario simple, donde el usuario respondería las principales preguntas: ¿Debió salir de su casa hoy? y ¿Cuál es su temperatura actual?',
			'Como la pandemia era nueva para todos, poco a poco se empezaron a conocer los síntomas más comunes, las situaciones de riesgo, y las maneras de prevenir contagio si es que debíamos salir de nuestras casas. Con esto cambió el flujo de la app, empezando por preguntar si la persona presentaba fiebre, si tenía síntomas y si salió, qué medios de transporte usó. Al final del check-in se presentaría un mensaje de acuerdo al resultado que obtuviera el usuario. '
		],
		analysis: [
			'La aplicación se podía usar en móvil (como aplicación nativa) y desde un navegador. La versión web permitió monitorear las interacciones de los usuarios a través de sesiones grabadas, con datos anonimizados con Hotjar. Esto nos permitió detectar cualquier confusión, problema de usabilidad o errores cuando la aplicación estaba recién empezando a ser utilizada por usuarios reales.',
			'Para entregar el feedback al equipo (en modalidad remota), generalmente se sacaban screenshots desde Hotjar en los momentos críticos y se enumeraban, explicando el problema y la solución que se podía ofrecer. Al principio se mostraron varios bugs en el front-end, mensajes de error confusos y affordances que no se cumplían en la interfaz de usuario.'
		],
		learnings: [
			'Siento que como la pandemia afectó a todos, enfocarnos en cómo piensa y siente el usuario final fue una tarea más sencilla, además de que obteníamos feedback directo de usuarios que ya habían implementado el sistema en sus lugares de trabajo en una etapa temprana del proceso.',
			'Definitivamente creo que en un principio se estaba apuntando a algo más ambicioso que involucraba cosas como uso de GPS para saber dónde habían estado los colaboradores dentro del lugar de trabajo, o que la toma de temperatura fuese obligatoria, pero con los meses y las distintas iteraciones se pudo simplificar de forma que la aplicación no se sintiera como una tarea tediosa y —en cierto sentido— invasiva. '
		]
	},
	{
		name: 'Decide Chile',
		tags: ['mobile', 'wireframes', 'interaction', 'data visualization'],
		details: {
			year: '2019 - 2020',
			client: 'Unholster (proyecto propio)',
			team: ['Project Manager', 'Tres diseñadoras', 'Desarrolladores'],
			role: [
				'Proponer primeras ideas de visualización.',
				'Construir las primeras ideas del sitio mobile-first en Sketch',
				'Proponer interacciones con los datos y navegación del usuario'
			]
		},
		context: [
			'DecideChile nació años atrás de mano de Unholster para visualizar e informar sobre resultados electorales en Chile. Con el estallido social de octubre del 2019, y como respuesta al descontento de la ciudadanía, se propuso una elección donde los chilenos decidirían si continuar on la constitución actual o redactar una nueva con una comisión o convención también elegida democráticamente. ',
			'Es con esto que surge la necesidad de crear un sitio que pudiese mostrar en tiempo real los resultados de este importante plebiscito que se realizaría en abril del 2020.'
		],
		goals: {
			main_goal:
				'Visualizar en tiempo real los resultados del Plebiscito Nacional 2020.',
			specific_goals: [
				'Visualizar resultados para ambas preguntas del plebiscito.',
				'Visualizar resultados a nivel nacional, regional y comunal.'
			]
		},
		users: [
			'En términos de investigación de usuario no hubo una etapa concreta, sino más bien se hicieron un par de proto-personas que después se confirmaron con los socios de la empresa, que anteriormente estuvieron altamente involucrados en el proyecto. Se definió como usuario a una persona mayor de 21 años, generalmente con estudios superiores e interés en la política nacional.'
		],
		process: [
			'Para empezar con las ideas primero se hicieron mock ups de baja fidelidad en sketch, en formato desktop, ya que era el formato conocido que se tenía de DecideChile. Estas fueron iteraciones cortas con feedback directo del Project Manager, por lo que las variantes fueron numerosas. '
		],
		challenges: [
			'En un principio la idea era tener una cantidad grande de círculos que representara cierta cantidad de votantes, y que éstos se fuesen distribuyendo entre las dos opciones que tenía cada pregunta. Al diseñar en formato móvil, el mayor desafío que se presentó fue el diseño de interacciones y la usabilidad del sitio en pantallas más pequeñas. Para eso se probaron diferentes ideas que comprendían distintos gestos para la navegación a través de los resultados electorales. Esta idea se descartaría por el tiempo de desarrollo que requeriría. En su lugar, se decidió una visualización estática, en el sentido de que no requeriría de una acción del usuario para obtener datos.'
		],
		learnings: [
			'Este fue un desafío corto en tiempo y desafiante en términos de diseño. El tiempo y la complejidad técnica de una visualización interactiva jugaron en contra. Sin embargo, el ritmo y periodicidad de las iteraciones fueron un excelente ejercicio en términos de feedback entre diseño y desarrollo. Conocer los límites técnicos permitió descartar a tiempo ideas que pudiesen haber sido muchísimo más costosas en tiempo de desarrollo.',
			'Este en particular fue uno de los proyectos que más he disfrutado, por la libertad creativa, la posibilidad de probar nuevas herramientas, nuevos formatos de visualización, el feedback constante y la posibilidad de trabajar más por el lado del diseño de visualización de datos.'
		]
	},
	{
		name: 'Factoring Security',
		tags: ['mobile', 'desktop', 'banking', 'UI', 'responsive design'],
		details: {
			year: '2019',
			client: 'Factoring Security',
			team: ['Project Manager', 'Diseñadora', 'Desarrolladores'],
			role: [
				'Proponer flujos de usuario en etapas tempranas del proyecto.',
				'Proponer wireframes.',
				'Diseñar UI en desktop y mobile.'
			]
		},
		context: [
			'Factoring Security es parte de Grupo Security, un holding que a través de distintas filiales, participa en banca, seguros de vida, banca, entre otros.',
			'Para este proyecto, el cliente buscaba una actualización de la interfaz que había sido implementada en otras secciones internas del sitio web. En particular se trataba de el Portal de Auto-Atención (PAA), donde los usuarios debían completar una serie de pasos para completar o simular operaciones de factoring, además de completar información sobre su empresa.'
		],
		goals: {
			main_goal: 'Realizar los procesos de factoring de manera 100% online.',
			specific_goals: [
				'Instar a los usuarios a realizar procesos online.',
				'Entregar una interfaz fácil de usar y que se adapte tanto a teléfonos como a computadores.'
			]
		},
		users: [
			'Siendo Factoring Security una empresa que ofrece soluciones a empresas, los usuarios finales serían personas que representan o administran cuentas de empresas de distintos tamaños y áreas del mercado. Son personas que manejan un lenguaje bancario y/o empresarial más avanzado, y que generalmente saben qué hacer y qué esperar cuando hacen este tipo de trámites o tareas.',
			'Se consideró también que los usuarios podían ser tanto clientes como no clientes. Todas las personas que quieran realizar una operación de factoring son evaluadas en base a documentos que deben mostrar durante el proceso. Dependiendo de esta evaluación se continúa con la operación o se aborta.'
		],
		process: [
			"Al tener distintos 'status', los usuarios podían seguir distintos flujos de navegación, por lo que fue necesaria una evaluación y mapeo de estos flujos para entender mejor el proceso y poder establecer los mockups que se necesitaban."
		],
		learnings: [
			'Este fue uno de esos proyectos que al principio parecen simples, ya que teníamos una base gráfica para diseñar proporcionada por el cliente; pero que terminó siendo desafiante debido a la complejidad de los flujos del usuario. Como diseñadora, no tenía gran conocimiento financiero o bancario, por lo que entender los procesos fue complicado al principio.',
			'Otra dificultad fue adaptar la interfaz a mobile, por la cantidad de tablas de datos que contenía el sitio. Las tablas son difíciles de implementar en espacios pequeños, pues la usabilidad se ve comprometida. Finalmente se llegó a una solución simple tanto en términos de UI como de front-end.'
		]
	},
	{
		name: 'PC Factory',
		tags: ['mobile', 'desktop', 'wireframes', 'hifi prototypes', 'retail'],
		details: {
			year: '2021',
			client: 'PC Factory',
			team: ['Project Manager', 'Líder UX', 'Dos diseñadores'],
			role: [
				'Investigar y evaluar referentes de retail online nacional e internacional.',
				'Diseñar wireframes con nueva diagramación de tienda online.',
				'Diseñar prototipos hifi tanto en mobile como en desktop.'
			]
		},
		context: [
			'Extraído desde la página de LinkedIn de la empresa:"pc Factory es una empresa chilena, que comenzó sus operaciones en septiembre del año 1996, transformándose durante este tiempo, en la mayor cadena de tiendas especialista en tecnología del país."',
			'Luego de un cambio en la imagen de marca, PC Factory tenía la misión de aplicar este rediseño a su tienda online, y así aprovechar de potenciar su presencia dentro del mundo del retail electrónico, aprovechando las cifras positivas que la pandemia del coronavirus traía en cuanto a ventas por internet.'
		],
		goals: {
			main_goal:
				'Ofrecer una experiencia de compra digital óptima, bajo el nuevo estándar de las cadenas de retail chilenas, de la mano de una imagen renovada de PC Factory como marca.',
			specific_goals: [
				'Mostrar a los usuarios ofertas, stocks y novedades en cuanto a tecnología.',
				'Ofrecer una experiencia fluida y eficaz durante el proceso de compra.',
				'Presentar el mismo nivel de experiencia en desktop y mobile.',
				'Representar la nueva imagen de PC Factory en cada una de las secciones del sitio.'
			]
		},
		users: [
			'Antes de construir user personas, se investigó sobre los perfiles de los usuarios de retail online. En particular llamó la atención un estudio llamado Estudio Online Shoppers 2016 hecho por Webloyalty, que propone 5 perfiles de compradores. A partir de eso definimos nuestros perfiles de usuarios:'
		],
		userprofiles: [
			'Foco en la navegación: Usuario que no tiene prisa, se lo toma con calma, por lo que presta más atención a los detalles. Si ve algo que de verdad le gusta, no duda en comprarlo. Siempre es bueno tener este tipo de usuario cerca e intentar convertirlos en clientes frecuentes.',
			'Foco en la investigación: Navega por objetivos, tiene una meta en mente, hay algo que quiere conseguir, generalmente un tipo de información específica sobre el producto, como la descripción, el precio, etc; por lo que es importante ser muy precisos al mostrar esa información. Este tipo de usuario también tiende a comparar los tipos de productos antes de tomar una decision.',
			'Foco en el producto: Son personas que saben exactamente lo que quieren. Saben adonde ir y cómo manejarse para lograr su objetivo. A estos usuarios les gusta la velocidad, por lo que es importante responder de manera rápida y eficaz.',
			'Cazarrecompensas: Siempre busca el mejor precio. Este usuario dará su email facilmente si saben que van a recibir cupones, descuentos u ofertas.'
		],
		benchmark: [
			'Con el objetivo de conocer mejor el estado y la experiencia del retail digital, se observaron y evaluaron una serie de sitios web, tanto nacionales (chilenos) como internacionales. Además de enfocar este benchmark en la experiencia del usuario, se puso atención a la arquitectura de información, la diagramación de los sitios y la interfaz de cada uno de ellos.'
		],
		process: [' '],
		learnings: [
			'Esta fue mi primera experiencia trabajando con una tienda online. Si bien tengo experiencia comprando por internet, hay muchas cosas que uno como comprador da por sentadas. A eso se refieren muchos como diseño invisible, cosas que no notas que están ahí pero que hacen que la experiencia sea lo más fluida posible.',
			'Varias características que se observaron en sitios internacionales como Best Buy o Amazon están siendo adoptadas por el retail chileno, lo que hace pensar que estamos yendo en la dirección correcta en cuanto a UX en e-commerce, pero aun nos falta para llegar a un nivel óptimo. Una de ellas es el servicio al cliente siempre disponible y de fácil acceso, o la valoración que tienen los clientes de los productos que compran. Muchas son las variables que hacen que una compra por internet simule la experiencia en una tienda física, y aumente la confianza del cliente.'
		]
	},
	{
		// Cotalker DS
		name: 'Cotalker DS',
		tags: ['Design system', 'user interface'],
		details: {
			year: '2023',
			client: 'Cotalker (proyecto interno)',
			team: ['Product Manager', 'Diseñadora'],
			role: [
				'Diseñar los componentes base para la interfaz de usuario de Cotalker',
				'Aplicar principios de Atomic Design',
				'Velar por la consistencia dentro de la interfaz',
				'Actualizar componentes en base al desarrollo de nuevas prestaciones de la plataforma'
			]
		},
		context: [
			'La interfaz de usuario de Cotalker se componía principalmente por componentes extraídos de Material UI usada como librería de Angular para el front-end de la plataforma.',
			'Esto facilitaba el desarrollo rápido, pues los componentes venían con estilos predeterminados. Sin embargo, el dolor de ésto se veía reflejado en que la plataforma presentaba un look genérico y que en instancias no conversaba con la paleta de colores creada hace años en Cotalker.'
		],
		goals: {
			main_goal:
				'Diseñar un Sistema de Diseño (o Design System) para Cotalker que sea consistente dentro de la interfaz y coherente con la imagen de marca.',
			specific_goals: [
				'Diseñar componentes utilizando principios de Atomic Design.',
				'Adaptar componentes ya existentes para que tengan los colores y tipografía correspondientes a Cotalker.',
				'Mejorar la consistencia dentro de la interfaz para evitar confusión en los usuarios.'
			]
		},
		process: [
			'Se tomó como base la paleta de Cotalker definida años atrás, además de una tipografía gratuita que se asemejara a la usada en el logo.',
			'Lo siguiente fue empezar a construir los componentes base utilizados en la plataforma.',
			'Una vez definidos los componentes base, se procede a crear componentes más complejos, nacidos de los componentes base (los átomos). De aquí comienza un proceso de creación incremental hasta llegar a la construcción de vistas completas (las páginas).'
		],
		learnings: [
			'Esta fue mi primera experiencia construyendo un sistema de diseño desde cero. Fue un desafío que al principio se sintió muy grande, por la cantidad de componentes que se necesitan para crear una aplicación.Sin embargo, una vez los componentes base estaban diseñados, el proceso fue incremental y lo principal se centró en asegurar que todas las piezas conversaran entre sí.',
			'Con esto entendí la importancia de la consistencia dentro de un producto digital, además de confirmar que un sistema de diseño jamás termina de actualizarse, pues evoluciona constantemente junto con el producto en sí.'
		]
	},
	{
		name: 'Visualización de datos',
		tags: ['Dashboards', 'Dataviz', 'Experimentation', 'UI'],
		taller_mercado: {
			subtitle: 'Afiche Taller Mercado II DNO0421 (2016)',
			content: [
				'Como parte del currículum de Diseño en la Universidad Católica, durante el 2016 tomé el curso Mercado II con el profesor Ricardo Vega, quien nos mostró herramientas digitales para crear piezas gráficas a través de la programación con Processing. Este proyecto era sobre un tema a elección, y en mi caso quise jugar con datos sobre Los Simpsons.'
			]
		},
		inteligencia_electrica: {
			subtitle: 'Dashboard inteligencia eléctrica (2018)',
			content: [
				'Dashboard diseñado para empresa de electricidad. Éste buscaba graficar el portal de pagos y visualizar data at-a-glance desde el inicio del sitio interno.'
			]
		},
		decidechile: {
			subtitle: 'Decide Chile: Plebiscito Nacional (2019-2020)',
			content: [
				'Visualización de datos electorales para el Plebiscito Nacional del 2020. Aquí se muestran alternativas de estilo gráfico y colores.'
			]
		},
		constitucionales: {
			subtitle: 'Decide Chile: Constitucionales (2020)',
			content: [
				'Visualización experimental de datos sobre los distritos para la elección de constituyentes, participantes encarargados de formar la Asamblea Constitucional que redactará la nueva Constitución de Chile. '
			]
		}
	}
];
