import React from 'react';
import List from './List';

const Goals = ({ maingoal, goals }) => {
	return (
		<div className="block">
			<div className="content">
				<h2 className="subtitle">Objetivos</h2>
				<div className="block">
					{/* TO-DO: A more generic way to display this information */}
					<strong>Objetivo principal:</strong> {maingoal}
				</div>
				<div className="block">
					<strong>Objetivos específicos:</strong>
					<List arr={goals} />
				</div>
			</div>
		</div>
	);
};

export default Goals;
