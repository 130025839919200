import React from 'react';
import { projects } from '../../data/projects';

// Components
import ProjectLanding from '../ProjectLanding';
import Footer from '../Footer';
import Text from '../Text';
import Goals from '../Goals';
import Navbar from '../Navbar';
import List from '../List';

// Images
import pcfactory_antiguo from '../../assets/images/pcfactory/oferta-pcfactory.png';
import persona1 from '../../assets/images/pcfactory/persona1.png';
import persona2 from '../../assets/images/pcfactory/persona2.png';
import benchmark1 from '../../assets/images/pcfactory/benchmark.png';
import benchmark2 from '../../assets/images/pcfactory/visualbenchmark.png';
import wireframes_mobile from '../../assets/images/pcfactory/Pcfactory_Wireframes.png';
import hifi_home from '../../assets/images/pcfactory/HomeDesktop.png';
import hifi_listado from '../../assets/images/pcfactory/ListadoProductos.png';
import hifi_mobile from '../../assets/images/pcfactory/mobilescreens.png';
import mockups from '../../assets/images/pcfactory/mockups.png';

const PcFactory = () => {
	const { name, tags, context, users, userprofiles, benchmark, learnings } =
		projects[4];
	const { year, client, team, role } = projects[4].details;
	const { main_goal, specific_goals } = projects[4].goals;
	return (
		<div>
			<Navbar />

			<div className="project">
				<div className="container">
					<div className="content">
						<ProjectLanding
							title={name}
							tags={tags.map((tag, index) => (
								<span key={index} className="tag is-rounded">
									{tag}
								</span>
							))}
							year={year}
							client={client}
							team={team}
							role={role}
						/>
						<hr />

						<div className="columns is-centered">
							<div className="column is-four-fifths">
								{/* Contexto */}
								<section className="section">
									<Text subtitle={'Contexto'} textarr={context} />
									<div className="block">
										<figure className="image">
											<img
												src={pcfactory_antiguo}
												alt="Screenshot del sitio web antiguo de PC Factory."
											></img>
											<figcaption>
												Screenshot del sitio web antiguo de PC Factory.
											</figcaption>
										</figure>
									</div>
								</section>

								{/* Objetivos */}
								<section className="section">
									<Goals maingoal={main_goal} goals={specific_goals} />
								</section>

								{/* Usuarios */}
								<section className="section">
									<Text subtitle={'Usuarios'} textarr={users} />
									<List arr={userprofiles} />

									<div className="block">
										<figure className="image">
											<img
												src={persona1}
												alt="User persona del usuario enfocado en el producto."
											></img>
											<figcaption>
												User persona del usuario enfocado en el producto.
											</figcaption>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img
												src={persona2}
												alt="User persona de usuaria enfocada en la navegación."
											></img>
											<figcaption>
												User persona de usuaria enfocada en la navegación.
											</figcaption>
										</figure>
									</div>
								</section>

								{/* Benchmark */}
								<section className="section">
									<Text subtitle={'Benchmark'} textarr={benchmark} />

									<div className="block">
										<figure className="image">
											<img
												src={benchmark1}
												alt="Benchmark en spreadsheet y características graficadas, encontradas a partir de esta evaluación."
											></img>
											<figcaption>
												Benchmark en spreadsheet y características graficadas,
												encontradas a partir de esta evaluación.
											</figcaption>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img
												src={benchmark2}
												alt="Benchmark visual que refleja insights y observaciones encontradas durante el benchmark."
											></img>
											<figcaption>
												Benchmark visual que refleja insights y observaciones
												encontradas durante el benchmark.
											</figcaption>
										</figure>
									</div>
								</section>

								{/* Proceso */}
								<section className="section">
									<h2 className="subtitle">Proceso</h2>

									<div className="block">
										<figure className="image">
											<img
												src={wireframes_mobile}
												alt="Wireframes del sitio móvil."
											></img>
											<figcaption>Wireframes del sitio móvil.</figcaption>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img
												src={hifi_home}
												alt="Página de inicio en hifi."
											></img>
											<figcaption>Página de inicio en hifi.</figcaption>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img
												src={hifi_listado}
												alt="Lista de productos desktop, en hifi."
											></img>
											<figcaption>
												Lista de productos desktop, en hifi.
											</figcaption>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img
												src={hifi_mobile}
												alt="Vistas en mobile, además de contenedores para ofertas."
											></img>
											<figcaption>
												Vistas en mobile, además de contenedores para ofertas.
											</figcaption>
										</figure>
									</div>
								</section>

								<section className="section">
									<Text subtitle={'Aprendizajes'} textarr={learnings} />
									<figure className="image">
										<img
											src={mockups}
											alt="Mockups que muestran el diseño final en laptop y teléfono móvil."
										></img>
									</figure>
								</section>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default PcFactory;
