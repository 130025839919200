import React from 'react';
const List = ({ arr }) => {
	return (
		<ul>
			{arr.map((element, index) => (
				<li key={index}>{element}</li>
			))}
		</ul>
	);
};

export default List;
