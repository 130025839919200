import React from 'react';
import { projects } from '../../data/projects';

// Components
import Navbar from '../Navbar';
import ProjectLanding from '../ProjectLanding';
import Text from '../Text';
import Goals from '../Goals';
import Footer from '../Footer';

// Images
import sketches from '../../assets/images/aplicaton/Aplicaton_Sketches.png';
import users from '../../assets/images/aplicaton/arquetipos-teleton@2x.png';
import testing from '../../assets/images/aplicaton/maze-heatmaps.png';
import stages from '../../assets/images/aplicaton/etapas-aplicaton@2x.png';
import learnings from '../../assets/images/aplicaton/aplicaton-screens@2x.png';
import mockups from '../../assets/images/aplicaton/mockups-3-phones.png';

const Aplicaton = () => {
	const team = projects[0].details.team;
	const role = projects[0].details.role;
	return (
		<div>
			<Navbar />
			<div className="project">
				<div className="container">
					<div className="content">
						<ProjectLanding
							title={projects[0].name}
							tags={projects[0].tags.map((tag, index) => (
								<span key={index} className="tag is-rounded">
									{tag}
								</span>
							))}
							year={projects[0].details.year}
							client={projects[0].details.client}
							team={team}
							role={role}
						/>
						<hr />

						<div className="columns is-centered">
							<div className="column is-four-fifths">
								<section className="section">
									<Text subtitle={'Contexto'} textarr={projects[0].context} />
								</section>
								<section className="section">
									<Goals
										maingoal={projects[0].goals.main_goal}
										goals={projects[0].goals.specific_goals}
									/>
								</section>

								{/* Users */}
								<section className="section">
									<Text subtitle={'Users'} textarr={projects[0].users} />
									<figure className="image">
										<img
											src={users}
											alt="Arquetipos de persona que dona dinero a la Teletón y persona que es voluntaria de la campaña."
										></img>
										<figcaption>
											Arquetipos de persona que dona dinero a la Teletón y
											persona que es voluntaria de la campaña.
										</figcaption>
									</figure>
								</section>

								{/* Process */}
								<section className="section">
									<Text subtitle={'Proceso'} textarr={projects[0].process} />

									<figure className="image">
										<img
											className="is-4by5 is-fullwidth"
											src={sketches}
											alt="Dibujos de diagramación y funcionalidad"
										></img>
										<figcaption>
											Dibujos de diagramación y funcionalidad
										</figcaption>
									</figure>
								</section>

								{/* Testing */}
								<section className="section">
									<Text
										subtitle={'Remote User Testing'}
										textarr={projects[0].user_testing}
									/>
									<figure className="image">
										<img src={testing} alt="Heatmaps generados por Maze."></img>
										<figcaption>Heatmaps generados por Maze.</figcaption>
									</figure>
								</section>

								<section className="section">
									<Text
										subtitle={'Hallazgos y observaciones'}
										textarr={projects[0].findings}
									/>
									<figure className="image">
										<img
											src={stages}
											alt="Proceso para el diseño de la secciones que tienen una diagramación estilo timeline o listado, como el inicio o la sección de eventos."
										></img>
										<figcaption>
											Proceso para el diseño de la secciones que tienen una
											diagramación estilo timeline o listado, como el inicio o
											la sección de eventos.
										</figcaption>
									</figure>
								</section>

								{/* Learnings */}
								<section className="section">
									<Text
										subtitle={'Aprendizajes'}
										textarr={projects[0].learnings}
									/>
								</section>

								{/* Final image */}
								<section className="section">
									<div className="block">
										<figure className="image">
											<img
												src={learnings}
												alt="Cuatro de las vistas definitivas de la aplicación."
											></img>
										</figure>
									</div>

									<div className="block">
										<figure className="image">
											<img
												src={mockups}
												alt="Mockups de la aplicación en teléfonos."
											></img>
										</figure>
									</div>
								</section>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Aplicaton;
