import React from 'react';
import List from './List';
const ProjectLanding = ({ title, tags, year, client, team, role }) => {
	return (
		<div className="section project-landing">
			<div className="columns is-centered">
				<div className="column is-four-fifths">
					<div className="level">
						<div className="level-left">
							<h1 id="project-title" className="title is-1">
								{title}
							</h1>
						</div>
						<div className="level-right">
							<span className="tags">{tags}</span>
						</div>
					</div>

					<div className="columns">
						<div className="column">
							<ul>
								<li>
									<strong>Año:</strong> {year}
								</li>
								<li>
									<strong>Cliente:</strong> {client}
								</li>
								<li>
									<strong>Equipo:</strong>
								</li>
								<List arr={team} />
							</ul>
						</div>

						<div className="details column">
							<strong>Mi rol en el proyecto:</strong>
							<List arr={role} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProjectLanding;
